






























































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';

// .v-card { border-radius: 16px !important; }

.v-btn { text-transform: uppercase; }

.box-assinatura {
  margin: 0 auto;
  max-width: 300px;
  text-align: center;

  margin-top: 50px;
}
